import { Box, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { sendRequest } from "../../../Api";
import { Consultation, DevSummary } from "../../../customTypes";
import { BlueButton, GreyButton } from "../../../styles/Buttons.styles";
import {
  DEFAULT_TRANSITION,
  convertToJSON,
  convertToMMSS,
  convertToTableDate,
} from "../../../utils";
import Loading from "../../Loading";
import { ConsultationContext } from "../../context/ConsultationContext";
import { UserContext } from "../../context/UserContext";
import UpdateableField from "../utils/UpdateableField";
import DevField from "./DevField";

type DevSummarizationValues = {
  promptTemplate: string;
  maxNewTokens: number;
  temperature?: number;
  topP?: number;
  topK?: number;
  summarizationLlmType?: string;
};

export default function DevSummarization() {
  const { consultation, update, setConsultation, setIsCurrentlyPolling } =
    useContext(ConsultationContext);
  const { doctorProfile } = useContext(UserContext);
  const [devSummary, setDevConsultation] = useState<DevSummary>();
  const [loading, setIsLoading] = useState<boolean>(true);
  const theme = useTheme();

  const getDevConsultation = async (createdTimestamp: string) => {
    return await sendRequest(
      `api/devSummary?createdTimestamp=${createdTimestamp}`,
      "GET"
    ).then((result) => {
      if (!result) return;
      return convertToJSON(result);
    });
  };

  const getAndSetNewestDevConsultation = async (cs?: Consultation) => {
    if (cs && cs.devSummarizations) {
      const newestDevSummarizationId = cs.devSummarizations.at(-1);

      if (newestDevSummarizationId) {
        setDevConsultation(await getDevConsultation(newestDevSummarizationId));
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getAndSetNewestDevConsultation(consultation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchCurrentDevConsultation = async (modifier: number) => {
    if (
      devSummary &&
      consultation &&
      consultation.devSummarizations &&
      devSummary?.createdTimestamp
    ) {
      const indexOfCurrent = consultation.devSummarizations.indexOf(
        devSummary.createdTimestamp
      );

      if (
        (indexOfCurrent === 0 && modifier < 0) ||
        (indexOfCurrent === consultation.devSummarizations.length - 1 &&
          modifier > 0)
      ) {
        return;
      }

      const previousDevSummarization = consultation.devSummarizations?.at(
        indexOfCurrent + modifier
      );
      if (previousDevSummarization) {
        const previousDevConsultation = await getDevConsultation(
          previousDevSummarization
        );
        setDevConsultation(previousDevConsultation);
      }
    }
  };

  const previous = () => switchCurrentDevConsultation(-1);
  const next = () => switchCurrentDevConsultation(1);

  const updateRating = async (rating: number) => {
    sendRequest(
      "api/devSummary",
      "PUT",
      JSON.stringify({
        createdTimestamp: devSummary?.createdTimestamp,
        rating: rating,
      })
    ).then((result) => {
      if (!result) return;
      setDevConsultation(convertToJSON(result));
    });
  };

  const devSummarize = async (values: DevSummarizationValues) => {
    if (consultation && consultation.transcribed && doctorProfile) {
      setIsCurrentlyPolling(true);
      const newestConsultation = await sendRequest(
        "api/devSummarize",
        "POST",
        JSON.stringify({
          createdTimestamp: consultation.createdTimestamp,
          input: consultation.transcribed,
          isDevSummary: true,
          ...values,
        })
      ).then((result) => {
        if (!result) return;
        const c: Consultation = convertToJSON(result);
        setConsultation(c);
        return c;
      });
      getAndSetNewestDevConsultation(newestConsultation);
      setIsCurrentlyPolling(false);
    }
  };

  const initialFormValues = {
    promptTemplate:
      devSummary?.promptTemplate ||
      `###Sie sind eine Schreibhilfe zur ärztlichen Dokumentation für (medical domain). Sie arbeiten unsichtbar für den Leser. Ich gebe Ihnen eine Abschrift eines Arzt-Patienten-Gesprächs. Sie fassen das Gespräch gemäß dem folgenden ouput-format und Schreibstil zusammen.  Nutzen Sie keine Anrede oder selbstständige Grußformel.

### Halten Sie sich strikt und ausschließlich an die im Arzt-Patienten-Gespräch bereitgestellten Informationen für diese Dokumentation. Lassen Sie alle Informationen weg, die während des Treffens nicht ausdrücklich besprochen wurden.

### Das ist das output-format:
Vorstellungsgrund: Ein-Satz-Zusammenfassung der vorliegenden Beschwerden.
Entwicklung der Beschwerden: Hintergrundinformationen, die zu den Beschwerden führen. Eine detaillierte, chronologische Beschreibung der primären Beschwerden, Symptome und deren Verlauf des Patienten. Integrieren Sie die Details gemäß dem OLD CARTS Mnemonik (Beginn, Ort, Dauer, Charakter, Begleitsymptome, Ausstrahlung, Zeitpunkt, Schweregrad) in die Erzählung, ohne sie in separate Punkte aufzubrechen. Wenn ein Teil der OLD CARTS-Informationen fehlt, lassen Sie ihn einfach aus und fügen Sie nur die gegebenen Informationen hinzu.
Frühere Krankengeschichte : Eine Zusammenfassung der vergangenen medizinischen Zustände, Operationen, Krankenhausaufenthalte und Allergien des Patienten. Auflistung Risikofaktoren wie Arterielle Hypertonie, Diabetes mellitus, Übergewicht, Hypercholesterinämie, Körperliche Inaktivität, Rauchen, Alkohol, Familienanamnese. Relevante Informationen zum Lebensstil, einschließlich Ernährung, körperliche Aktivität und Sport (Stunden/Woche), Beruf.

### Zusätzliche Informationen:
"(additional info)"
### Hier ist das Transkript:

{}

### Befolgen Sie streng folgende 3 Vorgaben für die Generierung Ihres Textes:
1. Wortwahl für den Text: (tonality)
2. Inhaltliches Detail-Level des Textes: (length)
3. Format des Textes: (formatting)
###`,
    maxNewTokens: devSummary?.maxNewTokens || 250,
    temperature: devSummary?.temperature || 0.01,
    topP: devSummary?.topP || 0.8,
    topK: devSummary?.topK || 50,
    summarizationLlmType: devSummary?.summarizationLlmType || "Sonnet",
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: devSummarize,
    enableReinitialize: true,
  });

  const infoFields = [
    {
      label: "Summarization response",
      value: devSummary?.summarized,
    },
  ];

  const getDevSummaryPosition = () => {
    if (
      devSummary &&
      devSummary.createdTimestamp &&
      consultation &&
      consultation.devSummarizations &&
      consultation.devSummarizations.length > 0
    ) {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            {consultation.devSummarizations.indexOf(
              devSummary.createdTimestamp
            ) + 1}{" "}
            of {consultation.devSummarizations.length}
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            {convertToTableDate(devSummary?.createdTimestamp || "")} (
            {devSummary?.createdTimestamp})
          </Typography>
        </Box>
      );
    }
  };

  const getInfoField = (infoField) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
        key={infoField.label}
      >
        <Typography
          sx={{
            width: "12rem",
            fontWeight: "600",
          }}
        >
          {infoField.label}
        </Typography>
        <Typography
          sx={{
            maxWidth: "70%",
          }}
        >
          {infoField.value}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "6rem",
        gap: "2rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <GreyButton
          onClick={previous}
          sx={{
            "&&": {
              height: "4.5rem",
              width: "15rem",
            },
          }}
        >
          Previous
        </GreyButton>
        <BlueButton
          type="submit"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          sx={{
            "&&": {
              height: "4.5rem",
              width: "15rem",
            },
          }}
        >
          DEV Summarize
        </BlueButton>
        <GreyButton
          onClick={next}
          sx={{
            "&&": {
              height: "4.5rem",
              width: "15rem",
            },
          }}
        >
          Next
        </GreyButton>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: "100%",
            width: "100%",
          }}
        >
          {getDevSummaryPosition()}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography
              sx={{
                width: "15rem",
                fontWeight: "600",
              }}
            >
              Rating
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                height: "5rem",
              }}
            >
              {[...Array(10)].map((e, i) => {
                const isCurrentlySelected =
                  parseInt(devSummary?.rating || "") === i + 1;
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: isCurrentlySelected ? "3rem" : "2rem",
                      height: isCurrentlySelected ? "3rem" : "2rem",
                      fontSize: isCurrentlySelected ? "18px" : "14px",
                      backgroundColor:
                        i < 3
                          ? theme.palette.error.main
                          : i < 7
                          ? "#ffff66"
                          : theme.palette.success.main,
                      borderRadius: "200px",
                      cursor: "pointer",
                      boxShadow: "0 0 1rem rgba(0, 0, 0, 0.125)",
                      fontWeight: isCurrentlySelected ? "600" : "400",
                      ...DEFAULT_TRANSITION,
                      "&:hover": {
                        opacity: "0.5",
                        width: "3rem",
                        height: "3rem",
                        fontSize: "18px",
                        fontWeight: "600",
                      },
                    }}
                    onClick={() => updateRating(i + 1)}
                  >
                    {i + 1}
                  </Box>
                );
              })}
            </Box>
          </Box>
          {infoFields.map((infoField) => getInfoField(infoField))}
          {consultation && (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "15rem",
                    fontWeight: "600",
                  }}
                >
                  {t("transcription duration")} (not versioned)
                </Typography>
                <Typography>
                  {consultation.batchTranscriptionDuration &&
                    convertToMMSS(`${consultation.batchTranscriptionDuration}`)}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "15rem",
                    fontWeight: "600",
                  }}
                >
                  {t("initial summarization duration")} (not versioned)
                </Typography>
                <Typography>
                  {consultation.summarizationDuration &&
                    convertToMMSS(`${consultation.summarizationDuration}`)}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "15rem",
                    fontWeight: "600",
                  }}
                >
                  {t("DEV summarization duration")}
                </Typography>
                <Typography>
                  {devSummary?.summarizationDuration &&
                    convertToMMSS(`${devSummary?.summarizationDuration}`)}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    width: "15rem",
                    fontWeight: "600",
                  }}
                >
                  {t("transcript")} (not versioned)
                </Typography>
                <UpdateableField
                  rows={10}
                  name="transcribed"
                  update={update}
                  defaultValue={consultation.transcribed}
                  consultation={consultation}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "12px",
                      height: "fit-content",
                    },
                  }}
                />
              </Box>
            </>
          )}
          <DevField formik={formik} fieldName="promptTemplate" multiline />
          <DevField
            formik={formik}
            fieldName="summarizationLlmType"
            options={[
              {
                value: "Sonnet",
              },
              { value: "Haiku" },
            ]}
          />
          <DevField formik={formik} fieldName="temperature" />
          <DevField formik={formik} fieldName="maxNewTokens" />
          <DevField formik={formik} fieldName="topP" />
          <DevField formik={formik} fieldName="topK" />
        </Box>
      )}
    </Box>
  );
}
