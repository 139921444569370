import { Box, TextField, Typography } from "@mui/material";
import PrettyDropdown from "../../custom/PrettyDropdown";

type DevFieldProps = {
  formik: any;
  fieldName: string;
  multiline?: boolean;
  options?: any[];
};

export default function DevField({
  formik,
  fieldName,
  multiline = false,
  options,
}: DevFieldProps) {
  return (
    <Box
      sx={{ width: "100%", display: "flex", alignItems: "center", gap: "1rem" }}
    >
      <Typography
        sx={{
          width: "15rem",
          fontWeight: "600",
        }}
      >
        {fieldName}
      </Typography>
      {options ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <PrettyDropdown
            currentlySelected={formik.values[fieldName]}
            onChange={(val) => {
              formik.setFieldValue(fieldName, val);
            }}
            options={options}
          />
        </Box>
      ) : (
        <TextField
          id={fieldName}
          sx={{
            "& .MuiInputBase-root": {
              height: "100%",
              width: "100%",
            },
            "&&": {
              height: "100%",
              width: "100%",
            },
          }}
          name={fieldName}
          value={formik.values[fieldName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
          helperText={formik.touched[fieldName] && formik.errors[fieldName]}
          multiline={multiline}
        />
      )}
    </Box>
  );
}
