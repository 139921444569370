import CircleIcon from "@mui/icons-material/Circle";
import {
  Box,
  Divider,
  Stack,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../../Api";
import { Consultation, Preset } from "../../customTypes";
import { BlueButton, GreyButton } from "../../styles/Buttons.styles";
import {
  ANAMNESIS,
  DEFAULT_BOX_SHADOW,
  convertToJSON,
  convertToMinSec,
} from "../../utils";
import Loading from "../Loading";
import { ConsultationContext } from "../context/ConsultationContext";
import { UserContext } from "../context/UserContext";
import PrettyDropdown from "../custom/PrettyDropdown";
import AdditionalPatientInformation from "./AdditionalPatientInformation";
import ConsentNote from "./ConsentNote";
import RecordingButtons from "./RecordingButtons";
import { useConsultation } from "./custom/useConsultation";
import { usePresets } from "./custom/usePresets";
import useRecorder from "./custom/useRecorder";
import { AudioReactRecorder, RecordState } from "./recording/Waveform";
import CardTopping from "./utils/CardTopping";
import Timer from "./utils/Timer";
import UpdateableField from "./utils/UpdateableField";

export default function MobileConsultationRecording() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [recordState, setRecordState] = useState<any>(RecordState.NONE);
  const urlParams = new URLSearchParams(window.location.search);
  const [createdTimestamp, setCreatedTimestamp] = useState<string | null>(
    urlParams.get("createdTimestamp")
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    consultation,
    update,
    createConsultation,
    createSummary,
    consultationNotFound,
    getConsultation,
  } = useConsultation(createdTimestamp, setIsLoading);
  const { setConsultation } = useContext(ConsultationContext);
  const [consultationRecordingDuration, setConsultationRecordingDuration] =
    useState<string | undefined>(consultation?.recordingDuration);
  const lang = urlParams.get("lang");

  useEffect(() => {
    getPresets();
    localStorage.setItem("language", lang || "de");
  }, [lang]);

  useEffect(() => {
    if (!createdTimestamp) {
      createNewConsultation();
    } else {
      getConsultation();
    }
  }, [createdTimestamp]);

  useEffect(() => {
    if (createdTimestamp && consultationNotFound) {
      navigate("/mobile");
      setCreatedTimestamp(null);
    }
  }, [consultationNotFound]);

  useEffect(() => {
    if (consultation) {
      setConsultationRecordingDuration(consultation.recordingDuration);
      setConsultation(consultation);
    }
  }, [consultation]);

  const createNewConsultation = async () => {
    const c = await createConsultation();
    setCreatedTimestamp(c.createdTimestamp);
    setConsultation(c);
    window.history.replaceState(
      {},
      "",
      `/mobile?createdTimestamp=${c.createdTimestamp}`
    );
  };

  const updateRecording = async (payload: any) => {
    if (createdTimestamp && user) {
      sendRequest(
        "api/consultation",
        "PUT",
        JSON.stringify({
          createdTimestamp: createdTimestamp,
          consultationStatus: "RECORDED",
          doctorId: user.username,
          ...payload,
        })
      ).then(async (result) => {
        if (!result) return;
        const c = convertToJSON(result);
        setConsultationRecordingDuration(c.recordingDuration);
      });
    }
  };

  const [currentPresetType, setCurrentPresetType] = useState<
    Consultation["presetType"]
  >(consultation?.presetType || ANAMNESIS);

  const changeCurrentPreset = (type: Consultation["presetType"]) => {
    setCurrentPresetType(type);
    update({ presetType: type });
  };

  const {
    isRecording,
    startRecording,
    isRecordingPaused,
    resumeRecording,
    pauseRecording,
    stopRecording,
    isUploading,
    time,
    error,
    setError,
    isRecordingFinished,
    reset,
  } = useRecorder(updateRecording, createSummary, consultation);

  const [presets, setPresets] = useState<Preset[]>([]);
  const { getPresets } = usePresets(setPresets, currentPresetType);

  const pulse = keyframes`
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(/mobileRecordingBackground.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        padding: "2rem",
        boxSizing: "border-box",
        justifyContent: "center",
        gap: "2rem",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/svg/Adiu_Logo.svg"
          sx={{
            maxWidth: "120px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              textAlign: "right",
              width: "5rem",
            }}
          >
            {t("encryptedAudioRecording")}
          </Typography>
          <Box
            component="img"
            src="/svg/shield.svg"
            sx={{
              width: "2rem",
            }}
          />
        </Box>
      </Box>

      <Box>
        <CardTopping>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {t("record")}
          </Typography>
        </CardTopping>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            minHeight: "30rem",
            width: "100%",
            padding: "2rem",
            borderRadius: "40px",
            ...DEFAULT_BOX_SHADOW,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            position: "relative",
            justifyContent: "center",
          }}
        >
          {!createdTimestamp || !consultation ? (
            <Loading />
          ) : consultation &&
            !isRecordingFinished &&
            !isUploading &&
            consultation.consultationStep === "RECORDING" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                gap: "1rem",
              }}
            >
              <PrettyDropdown
                currentlySelected={consultation.presetType}
                options={presets.map((p) => ({
                  value: p.type,
                }))}
                onChange={changeCurrentPreset}
                append={
                  <Typography
                    sx={{
                      color: theme.palette.grey[300],
                      fontSize: "1rem",
                    }}
                  >
                    {t("type")}
                  </Typography>
                }
                width="100%"
              />
              {consultation.presetType !== "Custom" && (
                <PrettyDropdown
                  currentlySelected={consultation.patientGender}
                  onChange={(value) => {
                    update({
                      patientGender: value,
                    });
                  }}
                  options={[
                    {
                      value: "FEMALE",
                      label: t("female"),
                    },
                    {
                      value: "MALE",
                      label: t("male"),
                    },
                  ]}
                  append={
                    <Typography
                      sx={{
                        color: theme.palette.grey[300],
                        fontSize: "1rem",
                      }}
                    >
                      {t("gender")}
                    </Typography>
                  }
                  width="100%"
                />
              )}

              <UpdateableField
                placeholder="Session name"
                name={"consultationName"}
                defaultValue={consultation.consultationName || ""}
                update={update}
              />
              <Box
                sx={{
                  display: "flex",
                  flexBasis: "50%",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "start",
                  gap: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <Box
                    component="img"
                    src="/svg/microphone.svg"
                    sx={{
                      width: "3.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (isRecording) {
                        stopRecording();
                      } else {
                        startRecording();
                      }
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      top: "0",
                      right: "0",
                      gap: "0.25rem",
                      position: "absolute",
                      border: `0.5px solid ${
                        isRecording && !isRecordingPaused
                          ? theme.palette.error.main
                          : theme.palette.secondary.dark
                      }`,
                      padding: "0.25rem 0.5rem",
                      borderRadius: "10px",
                      color: theme.palette.secondary.dark,
                      alignItems: "center",
                      transform: "scale(1)",
                      animation:
                        isRecording && !isRecordingPaused
                          ? `${pulse} 1.25s infinite`
                          : "none",
                    }}
                  >
                    <CircleIcon
                      sx={{
                        width: "0.4rem",
                        color:
                          isRecording && !isRecordingPaused
                            ? theme.palette.error.main
                            : theme.palette.secondary.dark,
                      }}
                    />
                    <Typography
                      sx={{
                        color:
                          isRecording && !isRecordingPaused
                            ? theme.palette.error.main
                            : theme.palette.secondary.dark,
                        fontSize: "85%",
                      }}
                    >
                      Rec
                    </Typography>
                  </Box>
                  {consultationRecordingDuration &&
                  !isRecording &&
                  !isUploading ? (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "22px",
                      }}
                    >
                      {convertToMinSec(consultationRecordingDuration)}
                    </Typography>
                  ) : isRecording ? (
                    <Timer time={time} />
                  ) : (
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "22px",
                      }}
                    >
                      {convertToMinSec("0")}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: recordState !== RecordState.START ? "none" : "block",
                }}
              >
                <AudioReactRecorder
                  state={recordState}
                  backgroundColor={theme.palette.secondary.main}
                  foregroundColor={theme.palette.primary.main}
                />
              </Box>
              {isUploading ? (
                <Loading size="2em" />
              ) : recordState !== RecordState.START ? (
                <Divider
                  sx={{
                    background: theme.palette.primary.main,
                  }}
                />
              ) : (
                <></>
              )}

              <Box
                sx={{
                  marginTop: "1rem",
                  display: "flex",
                  flexBasis: "50%",
                  justifyContent: "end",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {error && (
                  <Typography
                    sx={{
                      color: theme.palette.error.main,
                      fontWeight: "600",
                      marginBottom: "2rem",
                    }}
                  >
                    {error}
                  </Typography>
                )}
                <RecordingButtons
                  isRecording={isRecording}
                  isRecordingPaused={isRecordingPaused}
                  setRecordState={setRecordState}
                  pauseRecording={pauseRecording}
                  resumeRecording={resumeRecording}
                  startRecording={startRecording}
                  stopRecording={stopRecording}
                  setError={setError}
                />
                <ConsentNote />
                {consultation.presetType !== "Custom" && (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <AdditionalPatientInformation
                      updateMobile={update}
                      consultationMobile={consultation}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : isRecordingFinished ||
            consultation.consultationStep === "SUMMARY" ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                }}
              >
                {t("recordingCompleted")}
              </Typography>
              <BlueButton
                onClick={() => {
                  reset();
                  setCreatedTimestamp(null);

                  navigate("/mobile");
                }}
              >
                {t("createNewRecording")}
              </BlueButton>
            </Box>
          ) : (
            <Loading />
          )}
        </Box>
      </Box>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "center",
          width: "100%",
        }}
      >
        <GreyButton
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          {t("Go back to dashboard")}
        </GreyButton>
      </Stack>

      <Typography
        sx={{
          display: "flex",
          alignSelf: "center",
          textAlign: "center",
          width: "90%",
        }}
      >
        {t("imprint")}
      </Typography>
    </Box>
  );
}
